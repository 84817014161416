import React, { useState } from "react";
import Nav from "./Nav";
import HamburgerWrap from "../containers/HamburgerWrap";

const Menu = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <HamburgerWrap
      className={(menuActive && "menuActive") || "open"}
      onClick={toggleMenu}
    >
      <div className="line-wrap">
        <span className="line" />
        <span className="line" />
        <span className="line" />
        <span className="line" />
      </div>

      <Nav isOpen={menuActive} />
    </HamburgerWrap>
  );
};

export default () => <Menu />;
