import React from "react";
import Profile from "../assets/profile9.jpg";
import HomeWrap from "../styled-containers/HomeWrap";
import { Animated } from "react-animated-css";
import Likes from "../components/Likes";
import Intro from "../containers/Intro";
import LinkButton from "../components/LinkButton";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { NavLink } from "react-router-dom";
import Alive from "../assets/Alive.gif";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Dividor from "../components/Dividor";
import Header from "../containers/Header";

import tailwindLogo from '../assets/tailwindcss-mark.svg'
import svelteLogo from '../assets/svelte.svg'

export default function() {
  return (
    <div>
      <HomeWrap>
        <Header />
        {/* Animated fields comming up! */}
        <Animated className="home" id="home" animationOut="fadeOut">
          <ScrollAnimation animateIn="fadeIn" animateOut="zoomOut">
            {" "}
            <h1 className="hi-there">Hi There</h1>
            <div className="profile-wrapper">
              <Animated animationIn="flipInY">
                <a style={{ cursor: "initial" }} href="/#/dashboard">
                  <img
                    src={Profile}
                    className="profile-pic"
                    alt="profile-pjduxx"
                  />
                </a>
              </Animated>
            </div>
            <em className="headline">
              <p>- Music Addict, Night Owl, Constant Learner -</p>
            </em>
            <br />
          </ScrollAnimation>
          <a href="#intro-home"> </a>
        </Animated>
      </HomeWrap>

      <Intro className="intro-home" id="intro-home">
        <ScrollAnimation animateIn="zoomIn">
          <ScrollAnimation animateIn="fadeIn">
            {" "}
            <p>
              Dynamic
              <span className="black"> Front-End Developer </span> Located in{" "}
              <span className="black">Orlando, FL </span> Crafting{" "}
              <span className="black">Web Solutions </span> that Blend
              <span className="black"> Creativity</span> and{" "}
              <span className="black">Functionality, </span> Empowering{" "}
              <span className="black"> Seamless Interactions </span>
              that <span className="black">Harmoniously</span> Unite
              <span className="black"> Design </span>
              and
              <span className="last"> Performance</span>
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <div className="gif-wrap">
          <img
            className="gif-Alive"
            src={Alive}
            alt="it-is-alive-gif-doctor-frankenstine"
          />
        </div>

        <NavLink to="/About">
          <LinkButton to="/About">
            <span className="about-link">
              <h5>About Me</h5>
            </span>
          </LinkButton>
        </NavLink>
      </Intro>

      <Likes>
        <h3>All Day Long</h3>

        <Dividor />
        <Dividor pri />
        <Dividor primary />

        <h2>Some Of The Tech I Use To Accomplish My Goals</h2>
        <div className="tech-list">

        <img className="svelte-logo" src={svelteLogo} alt="svelte-logo" />

          <FontAwesomeIcon className="fa" icon={["fab", "docker"]} />

          <FontAwesomeIcon className="fa" icon={["fab", "sass"]} />
          <FontAwesomeIcon className="fa" icon={["fab", "react"]} />
          <FontAwesomeIcon className="fa" icon={["fab", "node"]} />
          <FontAwesomeIcon className="fa" icon={["fab", "aws"]} />
          <FontAwesomeIcon className="fa" icon={["fab", "github"]} />

          <img src={tailwindLogo} alt="tailwind-logo" />
          
            

        </div>
        {/*   
        thinKFUL Requirement....
        
             <div className="familiar-with flex-center">
          <h2>Familiar With The Following:</h2>
          <ol>
            <li>Mysql, (SQL)</li>
            <li>MondoDb</li>
            <li>PHP</li>
            <li>Brain.js</li>
          </ol>
        </div>*/}
      </Likes>
    </div>
  );
}
