import styled from "styled-components";

const MainLayout = styled.div`
position: relative;
height: 100%;
min-height: 100vh;
transition: all 0.5s ease-in-out;
width: 100%;

* {
box-sizing: border-box;
}
.tilted-top { 
  font-family: 'Titillium Web', sans-serif;
  transform: rotate(-90deg);
  margin: 35px auto;
  font-size: 14px;
  color: #222;
  }
  .tilted-top2 {

font-family: 'Titillium Web', sans-serif;
    transform: rotate(-90deg);
    position: fixed;
    bottom: 50%;
     left: 0px;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-size: 18px;
color: rgb(52, 52, 52);
  }
}
`;

export default MainLayout;
