import styled from "styled-components";

const Likes = styled.div`
  background: #f1f1f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;

  .familiar-with {
    h6 {
      font-family: "Open Sans", sans-serif;
      color: #262228;

      text-align: center;
    }
    ol li {
      color: #1f92d2;
      font-family: "Open Sans", sans-serif;
    }
  }

  h2 {
    font-family: "Open Sans", sans-serif;
    color: #262228;
    font-weight: 300;
    align-self: center;
    text-align: center;
    padding: 30px;
    width: 80%;
    max-width: 450px;
  }

  h3 {
    color: #222;
    margin-top: 20px;
    font-size: calc(12px + 0.2vw);
    font-weight: 400;
  }

  .tech-list {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 24%);
    grid-template-rows: repeat(2, 40%);
    grid-gap: 20px;

    justify-content: center;
    align-items: center;
    height: 400px;
    max-height: 500px;

    color: #1c92d2;
    @media only screen and (max-width: 737px) {
      height: 250px;
    }
    .fa {
      text-align: center;
      font-size: calc(40px + 3.5vw);



      @media only screen and (min-width: 2000px) {
        font-size: 135px;
      }
    }
  }
`;
export default Likes;
