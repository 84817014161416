import styled from "styled-components";

import "animate.css/animate.min.css";

const FooterWrap = styled.div`
  background-color: #f1f5f5;
  position: relative;



  display: flex;
  justify-content: center;

  align-items: center;
  flex-direction: column;

  .shy {
    margin-top: 50px;
    font-family: "Molengo", sans-serif;
    font-weight: 300;
    font-size: calc(20px + 0.1vw);
    letter-spacing: 1px;
    color: #444;
    text-transform: uppercase;
    &::after {
      content: " !";
      color: #2992d2;
    }
  }

  .foot-dividor {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2992d2;
  }

  .peace {
    transform: rotate(25deg);
    color: #fff;
    font-size: 7em;
    filter: drop-shadow(-9px 10px 1px #2992d2);
  }
  .say-hello {
    font-family: "Monoton";
    margin: 0 auto;
    font-size: calc(2.3em + 2.5vw);
    color: #fff;
    filter: drop-shadow(10px 8px 0px #2992d2);
    margin-bottom: 100px;
    text-align: center;
  }

  .footer-info {
    position: absolute;
    bottom: 0px;

    display: flex;
    background-color: #ffffff;
    color: #4f4f4f;

    padding: 20px;
    width: 100%;

    font-family: "Lato", sans-serif;

    h6 {
      flex: 1;
      font-size: 0.7em;
    }
    .social-footer {
      display: flex;
      justify-content: space-around;
      width: 150px;
      a {
        transition: all 150ms ease-in-out;
        color: #4f4f4f;
        &:hover {
          color: #3498db;
        }
      }
    }
    @media only screen and (max-width: 500px) {

      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0px auto;
    }
  }
`;

export default FooterWrap;
