import styled, { css } from "styled-components";

const LinkButton = styled.div`
  font-family: "Titillium Web", sans-serif;
  border-radius: 3px;

  padding: 20px;
  width: 180px;
  font-size: 19px;

  text-align: center;
  background-color: transparent;
  border: 2px solid #fff;

  color: #f5f5f5;
  margin: 50px;

  width: 180px;

  box-shadow: 0px 13px 10px -10px #333;

  transition: all ease-in-out 175ms;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 37px 20px -20px #77b1ad;
    transform: translate(0px, -10px) scaleX(1.1);
    font-size: 0.9em;
  }

  ${(props) =>
    props.primary &&
    css`
      margin: 20px;
    &:hover {
      box-shadow:  0px 37px 20px -20px #333;
  transform: translate(0px, -10px) scaleX(1.10);
  font-size: 0.7em;
color: #fff;

  box-shadow: inset 0 0 2px 2px #333;

`};
`;

export default LinkButton;
