import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Header from "../containers/Header";
import AdminDashboard from "../components/AdminDashboard";
import HomeWrap from "../styled-containers/HomeWrap";

const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:5000";

const Dashboard = () => {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleLogout = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        // If token is not available, consider the user already logged out
        setIsLoggedIn(false);
        history.push("/admin");
        return;
      }

      // Perform logout logic on the server
      const response = await fetch(`${baseURL}/api/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Clear the token from session storage
        sessionStorage.removeItem("token");
        setIsLoggedIn(false);
        history.push("/");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = sessionStorage.getItem("token");
        if (!token) {
          setIsLoggedIn(false);
        } else {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Authentication failed:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (loading) {
    // Show loading indicator or other UI while checking authentication
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Redirect to="/admin" />;
  }

  return (
    <HomeWrap>
      <Header primary />

      <AdminDashboard handleLogout={handleLogout} />
    </HomeWrap>
  );
};

export default Dashboard;
