import styled from "styled-components";
const HomeWrap = styled.div`
  .profile-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-pic {
    height: 360px;
    width: 360px;
    border-radius: 50%;

    border: 4px solid #fff;

    box-shadow: 10px 9px 0.5px #2570a3;
    animation-delay: 80s;
    &:hover {
      transition: all 0.5s ease-in-out;
    }

    @media screen and (max-width: 750px) {
      height: 180px;
      width: 180px;
    }
  }

  

  color: #fcfaea;
  background: #3498db;

  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 700px;

    font-size: 1.4em;

    .hi-there {
      font-size: 3em;

      text-align: center;
      margin: 0;
      font-family: "Pacifico", sans-serif;
      margin-bottom: 30px;
      color: #fcfaea;
    }

    p {
      margin: 50px auto;
      text-align: center;
      line-height: calc(1.1em + 0.5vw);
      font-weight: 300;
    }

    .headline {
      font-family: "Roboto", sans-serif;
      font-size: calc(20px + 1vw);
      text-align: center;

      font-weight: 300;
    }

    @media screen and (max-width: 600px) {
      min-height: 800px;
    }
  }
  .just-scroll {
    margin: 0px;
  }

  .intro-fill {
    padding-top: 85px;
    .fa {
      margin-left: 15px;
      font-size: 2em;
    }
  }

  .scroll-arrow {
    color: #fff;
  }

  .svelte-logo {

  }

`;

export default HomeWrap;
