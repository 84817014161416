import styled from "styled-components";

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #3498db;
  background: -webkit-linear-gradient(to bottom, #3498db, #41b3ff);
  background: linear-gradient(to bottom, #3498db, #41b3ff);

  h6 {
    margin: 0 auto;
  }
  p {
    transition: all 0.3s ease-in-out;

    font-family: "Titillium Web", sans-serif;
    letter-spacing: 0.03em;
    font-size: calc(15px + 2.5vw);
    line-height: calc(1.2em + 0.5vh);
    font-weight: 300;
    width: 95%;
    max-width: 1100px;

    text-align: center;
    color: #262228;
    margin: -70px auto 90px auto;
    text-wrap: balance;

    .black {
      color: #fff;
    }
    .last {
      color: #fff;
      text-shadow: 0 0 20px #fff;
    }
  }

  .tech-bottom {
    font-size: calc(16px + 0.5vw);
    width: 69%;
    max-width: 400px;
  }
  .learn-more {
    color: #fff;
    padding: 25px;
    font-family: "Titillium Web", sans-serif;

    margin: 50px;
    &:hover {
    }
  }
  .gif-wrap {
    display: flex;
    overflow: hidden;
    width: 275px;
    border-radius: 50%;
    border: 4px solid #fff;
    height: 275px;
    background-color: #111;

    .gif-Alive {
      min-width: 100%;
      min-height: 100%;
    }

    video {
    }
    .IdeaGif {
      flex: 1;
    }

    .about-link {
      display: flex;
    }
  }
  @media only screen and (max-width: 550px) {
    .gif-wrap {
      overflow: hidden;
    }
  }
`;

export default Intro;
