import React from "react";
import { NavLink } from "react-router-dom";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Logo from "../assets/logo1.png";
import NavWrap from "../containers/NavWrap";

export default class Nav extends React.Component {
  render() {
    const { isOpen } = this.props;
    //console.log("Nav is open", isOpen);
    const NavClass = isOpen ? "open" : "nav1";
    return (
      <NavWrap className={NavClass}>
        <ul>
          <li>
            <div className="logox">
              <NavLink activeClassName="activex" to="/Home">
                <img src={Logo} alt="logo for site" />
              </NavLink>
            </div>
          </li>
          <li>
            <NavLink activeClassName="activex" to="/About">
              About
            </NavLink>
          </li>

          <li>
            <NavLink

              activeClassName="activex"
              to="/Projects"
            >
              Projects
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink activeClassName="activex" to="/Contact">
              <p>Talk to me </p>
            </NavLink>
          </li>
          {" "}
          <li>
            <NavLink activeClassName="activex" to="/Resume">
              Resume
            </NavLink>
          </li>
          <li>
            {" "}
            <div className="social-nav">
              <a href="https://twitter.com/pjdazeux">
                {" "}
                <FontAwesomeIcon className="fa" icon={["fab", "twitter"]} />
              </a>
              <a href="https://www.facebook.com/https://www.facebook.com/pjdux/">
                <FontAwesomeIcon className="fa" icon={["fab", "facebook-f"]} />
              </a>
              <a href="https://www.linkedin.com/in/pjdux/">
                <FontAwesomeIcon className="fa" icon={["fab", "linkedin"]} />
              </a>

              <a href="mailto:info@pjdux.com">
                <FontAwesomeIcon className="fa" icon={["fas", "at"]} />
              </a>
            </div>
          </li>
        </ul>
      </NavWrap>
    );
  }
}
