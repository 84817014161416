import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  PjduxDashboard,
  ExcelDashboard,
} from "../containers/DashboardContainer";
import {
  DashboardWrapExcel,
  GridContainer,
  HeadingItem,
  GridItem,
  DeleteButton,
  DashboardWrapPjdux,
  GridContainerPjdux,
  HeadingItemPjdux,
  GridItemPjdux,
  DeleteButtonPjdux,
} from "../styled-containers/DashboardWrap";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://pjdux-site-server.vercel.app"
    : "http://localhost:5000";

function AdminDashboard({ handleLogout }) {
  const [formSubmissions, setFormSubmissions] = useState([]);
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const [currentDate, setCurrentDate] = useState(getDate());
  const [excelActive, setExcelActive] = useState(false); // State to track the active style set

  useEffect(() => {
    fetchFormSubmissions();
  }, []);

  const fetchFormSubmissions = async () => {
    try {
      const response = await fetch(`${baseURL}/api/form-entry/submissions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      setFormSubmissions(data);
    } catch (error) {
      console.error("Error fetching form submissions", error);
    }
  };

  const toggleSelectAll = () => {
    if (selectedSubmissions.length === formSubmissions.length) {
      setSelectedSubmissions([]);
    } else {
      setSelectedSubmissions(
        formSubmissions.map((submission) => submission.id)
      );
    }
  };

  const deleteSubmission = async (submissionId) => {
    try {
      const response = await fetch(
        `${baseURL}/api/form-entry/submissions/${submissionId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        console.error(`Error deleting form submission with ID ${submissionId}`);
      } else {
        fetchFormSubmissions(); // Refresh the list of form submissions
      }
    } catch (error) {
      console.error(
        `Error deleting form submission with ID ${submissionId}`,
        error
      );
    }
  };

  const deleteSelectedSubmissions = async () => {
    try {
      if (selectedSubmissions.length > 0) {
        for (const submissionId of selectedSubmissions) {
          await deleteSubmission(submissionId);
        }

        setSelectedSubmissions([]); // Clear selected submissions after deletion
      }
    } catch (error) {
      console.error("Error deleting form submissions", error);
    }
  };

  const areAllSubmissionsSelected =
    selectedSubmissions.length === formSubmissions.length;

  const toggleMessageExpansion = (submissionId) => {
    setExpandedMessageId((prevId) =>
      prevId === submissionId ? null : submissionId
    );
  };

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
  }

  // Function to toggle the styles
  const toggleStyles = () => {
    setExcelActive((prevState) => !prevState);
  };

  return (
    <div className="swap-styles">
      {/* Add a button to toggle the styles */}

      {/* Use the common DashboardWrap and GridContainer based on excelActive */}
      {excelActive ? (
        <ExcelDashboard
          formSubmissions={formSubmissions}
          selectedSubmissions={selectedSubmissions}
          deleteSubmission={deleteSubmission}
          deleteSelectedSubmissions={deleteSelectedSubmissions}
          areAllSubmissionsSelected={areAllSubmissionsSelected}
          toggleSelectAll={toggleSelectAll}
          currentDate={currentDate}
          expandedMessageId={expandedMessageId}
          toggleMessageExpansion={toggleMessageExpansion}
          handleLogout={handleLogout}
          toggleStyles={toggleStyles}
          excelActive={excelActive}
        />
      ) : (
        <PjduxDashboard
          formSubmissions={formSubmissions}
          selectedSubmissions={selectedSubmissions}
          deleteSubmission={deleteSubmission}
          deleteSelectedSubmissions={deleteSelectedSubmissions}
          areAllSubmissionsSelected={areAllSubmissionsSelected}
          toggleSelectAll={toggleSelectAll}
          currentDate={currentDate}
          expandedMessageId={expandedMessageId}
          toggleMessageExpansion={toggleMessageExpansion}
          handleLogout={handleLogout}
          toggleStyles={toggleStyles}
          excelActive={excelActive}
        />
      )}
    </div>
  );
}

export default AdminDashboard;
