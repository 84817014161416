import React from "react";

import {
  DashboardWrapExcel,
  GridContainer,
  HeadingItem,
  GridItem,
  DeleteButton,
  DashboardWrapPjdux,
  GridContainerPjdux,
  HeadingItemPjdux,
  GridItemPjdux,
  DeleteButtonPjdux,
  handleLogout,
} from "../styled-containers/DashboardWrap";

// Excel Styles

export const ExcelDashboard = ({
  formSubmissions,
  selectedSubmissions,
  deleteSubmission,
  deleteSelectedSubmissions,
  areAllSubmissionsSelected,
  toggleSelectAll,
  currentDate,
  expandedMessageId,
  toggleMessageExpansion,
  handleLogout,
  toggleStyles,
  excelActive,
}) => {
  return (
    <DashboardWrapExcel>
      <h3 className="welcome-message">
        You've Got {formSubmissions.length} Messages :)
      </h3>

      <br />
      <br />
      {selectedSubmissions.length > 0 && (
        <DeleteButton onClick={deleteSelectedSubmissions}>
          Delete Selected ({selectedSubmissions.length})
        </DeleteButton>
      )}
      <button
        style={{
          background: "transparent",
          border: "none",
          color: "#fff",
          padding: "10px",
          boxShadow: "inset 0 0 1px 1.5px #fff",
          borderRadius: "4px",
          position: "absolute",
          left: "20px",
          top: "50px",
        }}
        onClick={toggleStyles}
      >
        {excelActive ? "Switch to Pjdux" : "Switch to Excel"}
      </button>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <GridContainer>
        <HeadingItem>
          Select All
          <input
            className="grid-checkbox"
            type="checkbox"
            checked={areAllSubmissionsSelected}
            onChange={toggleSelectAll}
          />
        </HeadingItem>
        {/* For screens larger than 580px, display the headings */}
        <HeadingItem className="heading">Name</HeadingItem>
        <HeadingItem className="heading">Email</HeadingItem>
        <HeadingItem className="heading">Message</HeadingItem>
        <HeadingItem className="heading">Delete</HeadingItem>
        {formSubmissions.map((submission) => (
          <React.Fragment key={submission.id}>
            {/* For screens larger than 580px, display the checkboxes */}
            <GridItem justifyContent="flex-end" className="heading">
              {currentDate}
            </GridItem>
            <GridItem>
              <span className="current-date">{currentDate}</span>
              {submission.name}
            </GridItem>
            <GridItem>{submission.email}</GridItem>
            <GridItem
              expanded={expandedMessageId === submission.id}
              onClick={() => toggleMessageExpansion(submission.id)}
            >
              {submission.message}
            </GridItem>
            <GridItem>
              <DeleteButton onClick={() => deleteSubmission(submission.id)}>
                Delete
              </DeleteButton>
            </GridItem>
          </React.Fragment>
        ))}
      </GridContainer>
    </DashboardWrapExcel>
  );
};

// Pjdux Site Native styles

export const PjduxDashboard = ({
  formSubmissions,
  selectedSubmissions,
  deleteSubmission,
  deleteSelectedSubmissions,
  areAllSubmissionsSelected,
  toggleSelectAll,
  currentDate,
  expandedMessageId,
  toggleMessageExpansion,
  handleLogout,
  toggleStyles,
  excelActive,
}) => {
  return (
    <DashboardWrapPjdux>
      <h3 className="welcome-message">
        You've Got {formSubmissions.length} Messages :)
      </h3>

      <br />
      <br />
      {selectedSubmissions.length > 0 && (
        <DeleteButtonPjdux onClick={deleteSelectedSubmissions}>
          Delete Selected ({selectedSubmissions.length})
        </DeleteButtonPjdux>
      )}
      <button
        style={{
          background: "transparent",
          border: "none",
          color: "#fff",
          padding: "10px",
          boxShadow: "inset 0 0 1px 1.5px #fff",
          borderRadius: "4px",
          position: "absolute",
          left: "22px",
          top: "50px",
        }}
        onClick={toggleStyles}
      >
        {excelActive ? "Switch to Pjdux" : "Switch to Excel"}
      </button>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <GridContainerPjdux>
        <HeadingItemPjdux>
          Select All
          <input
            className="grid-checkbox"
            type="checkbox"
            checked={areAllSubmissionsSelected}
            onChange={toggleSelectAll}
          />
        </HeadingItemPjdux>
        {/* For screens larger than 580px, display the headings */}
        <HeadingItemPjdux className="heading">Name</HeadingItemPjdux>
        <HeadingItemPjdux className="heading">Email</HeadingItemPjdux>
        <HeadingItemPjdux className="heading">Message</HeadingItemPjdux>
        <HeadingItemPjdux className="heading">Delete</HeadingItemPjdux>
        {formSubmissions.map((submission) => (
          <React.Fragment key={submission.id}>
            {/* For screens larger than 580px, display the checkboxes */}
            <GridItemPjdux justifyContent="flex-end" className="heading">
              {currentDate}
            </GridItemPjdux>
            <GridItemPjdux>
              {" "}
              <span className="current-date">{currentDate}</span>
              {submission.name}
            </GridItemPjdux>
            <GridItemPjdux>{submission.email}</GridItemPjdux>
            <GridItemPjdux
              expanded={expandedMessageId === submission.id}
              onClick={() => toggleMessageExpansion(submission.id)}
            >
              {submission.message}
            </GridItemPjdux>
            <GridItemPjdux>
              <DeleteButtonPjdux
                onClick={() => deleteSubmission(submission.id)}
              >
                Delete
              </DeleteButtonPjdux>
            </GridItemPjdux>
          </React.Fragment>
        ))}
      </GridContainerPjdux>
    </DashboardWrapPjdux>
  );
};
