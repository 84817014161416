import React from "react";
import styled from "styled-components";
import HashBanksLogo from "../assets/hashBanksLogo.png";
import Header from "../containers/Header";
import LinkButton from "../components/LinkButton";
import AllHeroes from "../assets/all-heroes.jpg";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import MockDataLogo from "../assets/mock-data-logo.png";

const Projects = styled.div`
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  h1 {
    color: #f5f5f5;
  }
`;

const Project = styled.div`
  background-color: #222;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 600px;

  h2 {
    color: #f5f5f5;
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    color: #f5f5f5;
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const Project3 = styled(Project)`
  img {
    width: 150px;
  }
`;

const ProjectImage = styled.img`
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const ProjectDescription = styled.p`
  font-size: 20px;
  letter-spacing: 1.5px;
  padding-bottom: 20px;
`;

const ProjectLink = styled.a`
  display: inline-block;
  margin-bottom: 10px;
`;

export default () => (
  <Projects>
    <Header primary />
    <br />

    <Project3>
      <ProjectImage src={MockDataLogo} alt="Brace Illustration" />
      <h2>Friendly Data Generator</h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
        While engaging with diverse banking interfaces and integrations, I've
        observed that developers often find themselves in situations where they
        end up typing JSON data on the fly to adhere to their adhock mock-data
        needs. This App significantly streamlines this process, and offers a
        nice interface for users to use. This tool allows for seamless
        visualization and one-click copying of return data enhancing efficiency
        and convenience.
      </ProjectDescription>
      <ProjectLink
        href="https://friendly-data-generator.vercel.app/"
        target="_blank"
      >
        <LinkButton primary>View Project</LinkButton>
      </ProjectLink>
    </Project3>

    <Project>
      <ProjectImage src={HashBanksLogo} alt="HashBanks Logo" />
      <h2>#Banq</h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
        Jam-Stack App where users can quickly create hashtags with no need to
        enter a hashtag. The user has the option to create hashtag Banks.
      </ProjectDescription>
      <ProjectLink href="https://hashbanks.vercel.app/#/" target="_blank">
        <LinkButton primary>View Project</LinkButton>
      </ProjectLink>
    </Project>

    {/*

    <Project3>
    <FontAwesomeIcon className="fa-3x blue-react" icon={["fab", "react"]} />
      <h2></h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
       Begginer React projects to kick start beginners, project has a few simple components I use to mentor Others. Needs renaming I know...
      </ProjectDescription>
      <ProjectLink href="https://tax-calculator-ashy.vercel.app/" target="_blank">
        <LinkButton primary>View Project</LinkButton>
      </ProjectLink>
    </Project3>
    <Project>
      <ProjectImage src={AllHeroes} alt="Heroes" />
      <h2>Super Hero Stats</h2>
      <p>Developer/Designer</p>
      <ProjectDescription>
        Fun app to look up your favorite hero and see information like name,
        powers, jobs, and more.
      </ProjectDescription>
      <ProjectLink href="https://pjdaze.github.io/my-hero-stats-redux/" target="_blank">
        <LinkButton primary>View Project</LinkButton>
      </ProjectLink>
    </Project>

    <Project3>
      <h2>Sad Cookie</h2>
      <div className="cookie">
      <div className="cookie">
  <div className="cookie-eye sad">
    <div className="cookie-rim sad" />
  </div>
  <div className="left-foot" />
  <div className="right-foot" />
</div>
      </div>
      <p>Developer/Designer</p>
      <ProjectDescription>
        Inspired by Black Mirror's episode "White Christmas". Improvements
        coming soon!
      </ProjectDescription>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ProjectLink href="https://pjdaze.github.io/sad-cookie-reactjs/" target="_blank">
          <LinkButton primary>View Project (ReactJS)</LinkButton>
        </ProjectLink>
        <ProjectLink href="https://codepen.io/pjdux/pen/xYVwra" target="_blank">
          <LinkButton primary>View Project (Vanilla JS)</LinkButton>
        </ProjectLink>
      </div>
    </Project3>

*/}
  </Projects>
);
