import styled from "styled-components";

export const ResumeWrapper = styled.div`

  color: #222;
  min-height: 100vh;

background-color: #222;
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


.resume {

}

  .resume-img {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }


  @media only screen and (max-width: 500px) {



  }

`;
