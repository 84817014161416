import React from "react";

import { Animated } from "react-animated-css";
import Header from "../containers/Header";
import Bob2 from "../assets/Bob3.png";

import Forrest from "../assets/Forrest6.gif";
import AboutWrapper from "../styled-containers/AboutWrapper";


const videoStyle = {
  with: "450px",
};

const About = (props) => {
  return (
    <AboutWrapper>
      <Header primary />
      <Animated className="fadeIn about">
        <h1>
          So <span className="who">Who</span> Am I
          {/*               
          thinKFUL Requirement....      
          <hr />
          */}
        </h1>

        <p>
        Greetings! I'm Pedro Diaz, a passionate JavaScript developer with a love for both code and music. Ever since I started my programming journey back in 2016, JavaScript has been my constant companion, and I'm continually amazed by its versatility and power.
<br/>
<br/>
One of the most rewarding aspects of my career is the opportunity to work with teams of all sizes, each bringing diverse approaches and ideas to the table. Whether collaborating with a small, tight-knit group or contributing to larger projects with a global team, I relish the experience of collective creativity. The exchange of ideas and perspectives fuels my growth as a developer and enriches the final product we create together.
<br/><br/>
In my journey as a JavaScript enthusiast, I've had the privilege of participating in projects spanning various industries and technologies. From front-end development to back-end systems and everything in between, I'm constantly expanding my skill set to tackle new challenges.
<br/><br/>
I believe that the best solutions emerge through collaboration, and I'm always eager to learn from my teammates and share my knowledge with them. The excitement of tackling complex problems together and witnessing the transformative power of teamwork motivates me to bring my best to every project.
<br/><br/>
Outside of the coding realm, you'll often find me playing music or attending concerts. Just as coding allows me to create something tangible and impactful, music lets me express myself and connect with others on a different level.
<br/>
<br/>
As my journey in the world of JavaScript and beyond continues, I look forward to exploring new horizons, embracing fresh challenges, and making a positive impact with my skills and passions. Let's create, collaborate, and make meaningful contributions together!
        </p>

        <div className="forest-gif-container">
          <img
            className="forest-gif"
            type="video/mp4"
            playsInline={true}
            loop={true}
            autoPlay={true}
            src={Forrest}
            style={videoStyle}
            alt="forest-gump-funny-gif"
          />
        </div>
      </Animated>
    </AboutWrapper>
  );
};

export default About;
