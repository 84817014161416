import React from "react";
import ContactWrapper from "../styled-containers/ContactWrapper";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Header from "../containers/Header";

import { NavLink } from "react-router-dom";
import { Animated } from "react-animated-css";
import ContactForm from '../components/ContactForm'

const Contact = (props) => (
  <ContactWrapper>
    <Header />
    <ContactForm />
    <div className="contact">
      <Animated animationIn="fadeInUp" className="contact-social">
        <a href="mailto:pjdazeux@gmail.com">
          <FontAwesomeIcon className="fa" icon={["fas", "at"]} />
        </a>

        <a href="https://www.linkedin.com/in/pjdux/">
          <FontAwesomeIcon className="fa" icon={["fab", "linkedin-in"]} />
        </a>

        <a href="https://github.com/Pjdaze">
          <FontAwesomeIcon className="fa" icon={["fab", "github"]} />
        </a>

        <a href="https://twitter.com/pjdazeux">
          {" "}
          <FontAwesomeIcon className="fa" icon={["fab", "twitter"]} />
        </a>

        <Animated animationIn="fadeInLeft" className="home-link">
          <NavLink to="/Home">
            <h6>TAKE ME HOME</h6>
          </NavLink>
        </Animated>
      </Animated>
    </div>
  </ContactWrapper>
);

export default Contact;
