import React from "react";
import FooterWrap from "../containers/FooterWrap";
import {NavLink} from 'react-router-dom';

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Dividor from "../components/Dividor";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";

const Footer = (props) => {
  return (
    <FooterWrap>
      <div className="foot-dividor">
        <h6 className="shy">Don't Be Shy</h6>
        <Dividor />
        <Dividor pri />
        <Dividor primary />
      </div>
      <ScrollAnimation
        animateIn="slideInUp"
        animateOut="zoomOutUp"
        duration={0.7}
      >

<NavLink arial-label="Go to contact page" activeClassName="activex" to="/Contact">
<FontAwesomeIcon className="fa peace" icon={["far", "hand-spock"]} />
            </NavLink>

      </ScrollAnimation>
      <NavLink arial-label="Go to contact page" activeClassName="activex" to="/Contact">
      <h2 className="say-hello">Say Hello!</h2>
      </NavLink>
      <div className="footer-info">
        <h6>
          <FontAwesomeIcon className="fa" icon={["far", "copyright"]} /> - A
          Pjdux Design
        </h6>

        <br />
        <div className="social-footer">
          <a href="mailto:info@pjdux.com" aria-label="Send me an email">
            <FontAwesomeIcon className="fa" icon={["fas", "at"]} />
          </a>

          <a href="https://www.linkedin.com/in/pjdux/" aria-label="Go to My linkeding Profile">
            <FontAwesomeIcon className="fa" icon={["fab", "linkedin"]} />
          </a>

          <a href="https://gitlab.com/Pjdux" aria-label="Go to my gitlab">
            <FontAwesomeIcon className="fa" icon={["fab", "github-square"]} />
          </a>

          <a href="https://twitter.com/pjdazeux" aria-label="Check out my twitter">
            {" "}
            <FontAwesomeIcon className="fa" icon={["fab", "twitter-square"]} />
          </a>
        </div>
      </div>
    </FooterWrap>
  );
};

export default Footer;
