import React from "react";
import styled from "styled-components";

import Nav from "../components/Nav";

const Header = styled.div`
  width: 100%;
  color: ${(props) => (props.primary ? "#222" : "#222")};
  overflow: hidden;
  background-color: ${(props) => (props.primary ? "#222" : "transparent")};
  background: transparent;
`;
/* eslint-disable */

export default (props) => (
  <Header className="header" id="header">
    <Nav primary />
  </Header>
);
