import styled from "styled-components";

const LoginFormWrapper = styled.div`

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;



height: 90vh;
min-height: 500px;
background-color: #c2d3da;

h2 {
  color: #fff;

  text-shadow: 1px 1px #222;
}

form {
  width: 600px;
}

  .fa {
  margin: 10px;
    transition: all 0.2s ease-in-out;
    color: #222;
   font-size: calc(2em + 2.5vw);


    &:hover {
      color: #fff;

    }
  }


}



`;


export default LoginFormWrapper;
