import React, { useState } from "react";

// Use the appropriate API URL based on the environment
const baseURL = process.env.NODE_ENV === 'production'
? process.env.REACT_APP_API_BASE_URL
: 'http://localhost:5000';




const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });


  const [showToast, setShowToast] = useState(false);
  const toastDuration = 1300;


  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseURL}/api/form-entry/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Clear form data on successful submission
        setShowToast(true);
        resetForm();
        setTimeout(() => {
          setShowToast(false);
        }, toastDuration);
      } else {
        console.error("Error submitting form");
      }
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  // reset
  const resetForm = () => {
    setFormData({ name: "", email: "", message: "" });
  };

  // handleChange
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (

      <form className="contact-form" onSubmit={onFormSubmit}>
      <div className="form-field">
        <label htmlFor="name">
          <div className="label-content">Name</div>
          <input
            placeholder="Full Name"
            onChange={handleChange}
            type="text"
            name="name"
            required
            value={formData.name}
          />
        </label>
      </div>

      <div className="form-field">
        <label htmlFor="email">
          <div className="label-content">Email</div>
          <input
            placeholder="Email"
            onChange={handleChange}
            type="email"
            name="email"
            required
            value={formData.email}
          />
        </label>
      </div>

      <div className="form-field">
        <label htmlFor="message">
          <div className="label-content">Message</div>
          <textarea
            placeholder="Character Limit 100"
            value={formData.message}
            onChange={handleChange}
            className="stretch"
            name="message"
            rows="5"
            required
          />
        </label>
      </div>

      <input type="submit" className="submit-input" value="Submit" />

   
      {showToast && (
  <div className={`toastMessage`}>
    <span>Message Sent! I will get back to you ASAP.</span>
  </div>
)}

    </form>



  );
};
export default ContactForm;
