import styled from "styled-components";

const NavWrap = styled.nav`

  width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 50px 35px;
  height: 0;
  transition: all 0.2s ease-in-out;
  overflow: visible;
  

  .logox {
    img {
      height: 200px;
    }
    &.active {
      opacity: 0.5;
      transition: all 2s ease-in-out;
    }
  }
  &.open {
    height: 100vh;
    padding-top: 25%;
    background-color: rgba(000, 000, 000, 0.9);

    li:nth-child(2) {
      display: block;
    }

    li:nth-child(3) {
      display: block;
    }
  }

  ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    font-size: 0.9em;

    img {
      height: 125px;
      width: 125px;
      align-self: center;
    }
  }

  li {
    display: block;
    font-family: "Titillium Web", sans-serif;

    font-weight: 300;
    color: #fff;

    a {
      color: #fff;
    }
    &.activex {
      color: #fcfaea;
      font-weight: bold;
    }
    &:hover {
      font-weight: bold;
      zoom: 1.01;
      transition: all 180ms ease-in-out;
    }

    &:first-child {
      margin-right: auto;
      margin-top: -20px;
      zoom: 1;
      &:hover {
        text-shadow: 0 0 50px #f2f2f2;
      }
    }

    &:nth-child(3) {
      margin-left: 15px;
    }

    &:nth-child(4) {
      display: none;
    }
    &:nth-child(5) {
      margin-left: 15px;
    }

  }
  // media queries//

  @media only screen and (max-width: 550px) {
    margin: 0;
    padding: 0;
    overflow: hidden;

    ul {
      display: flex;

      flex-direction: column;
      height: 100%;
    }
    li {
      font-size: 1.5em;
      margin-bottom: 15px;
      width: 100%;
      text-align: center;




      a {
        color: #fff;
        font-family: "Titillium Web", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
      }

      &:nth-child(3) {
        margin-left: 0;
        zoom: 1;
        &:hover {
          text-shadow: 0 0 50px #f2f2f2;
        }
      }
      &:nth-child(2) {
        zoom: 1;
        &:hover {
          text-shadow: 0 0 50px #f2f2f2;
        }
      }
      &:last-child {
        zoom: 1;
      }

      &:nth-child(4) {
        display: block;
        font-size: 12px;
        zoom: 2;

      }

      &:nth-child(5) {


margin: 0 auto;

      }
    }
  }
  //end of query

  .social-nav {
    height: 100px;
    width: 200px;
    padding: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: transparent;
    }

    .fa {
      transition: all 0.1s ease-in-out;
      color: #8c8c8c;
      &:hover {
        color: #f1f1f1;
      }
    }
    @media only screen and (min-width: 527px) {
      display: none;
    }
  }
`;

export default NavWrap;
