import styled from "styled-components";

const AboutWrapper = styled.div`
         background-color: #77c9d4 !important;
           min-height: 100vh;
         color: #fff;

.story {


  img {
    width: 100%;
  max-width: 180px;
    height: 180px;
  }

}

.feed {

  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 180px);
  grid-auto-rows: 1fr;
  grid-gap: 20px 20px;


}
  .about {


        display: flex;
        flex-direction: column;

        padding: 100px 0 100px 0;
        align-items: center;
        min-height: 100vh;
        color: #fff;
        width: 90%;
        max-width: 850px;
        line-height: 1.5em;
        font-family: 'Open Sans', sans-serif;

         p  {
          font-size: 0.9em;
           letter-spacing: 0.1em;
         }
.celeb {
  width: 95%;
  max-width: 550px;
}

.bob {
display: inline-block;
border-radius: 50%;
padding: 10px;
background: #fff;
margin: 0 10px;
  img{

  width: 50px;
  margin: 0 auto;

  }
}

      font-weight: 100;
      margin: 0 auto;
      h1 {
        text-align: center;
        line-height: 1.5em;
        font-family: 'Pacifico', sans-serif;
            font-size: 2.5em;

      }

}

.beatle {

width: 125px;
height: 80px;
img {
  width: 200px;
height: 100px;
}

}

.who {

letter-spacing: 0.2em;

}

}


.forest-gif-container {

  margin-top: 40px;


  width: 450px;

  height: 200px;



.forest-gif {
width: 100% !important;
height: 225px;

}


  @media only screen and (max-width: 500px){

    align-self: center;
    width: 70%;
    max-width: 250px;
    height: 200px;
    max-height: 300px;



  }



}

`;
export default AboutWrapper;
