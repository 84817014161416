import React from "react";
import MainLayout from "./styled-containers/MainLayout";
import Footer from "./components/Footer";
import Routes1 from "./routes";
import Hamburger from "./components/Hamburger";
import "font-awesome/css/font-awesome.css";

import fontawesome from "@fortawesome/fontawesome";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import brands from "@fortawesome/fontawesome-free-brands";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import regular from "@fortawesome/fontawesome-free-regular";
import ScrollToTop from "react-scroll-up";

import { NavLink } from "react-router-dom";
import "./styles.css";
fontawesome.library.add(brands, regular, faCheckSquare, faCoffee);
const App = () => {

  let style = {
    position: "fixed",
    bottom: 100,
    right: 0,
    cursor: "pointer",
    transitionDuration: "0.2s",
    transitionTimingFunction: "linear",
    transitionDelay: "0s",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    fontSize: "25px",
    color: "#ffffff",
  };
  return (
    <MainLayout>
      <Hamburger />

      <Routes1 />
      <Footer />
      <ScrollToTop
        style={style}
        className="scroll"
        duration={1000}
        showUnder={780}
      >
        <FontAwesomeIcon icon={["far", "arrow-alt-circle-up"]} />
        <p className="tilted-top">Back To Top</p>
        <NavLink activeClassName="activex" to="/Contact">
          <p className="tilted-top2">Contact</p>
        </NavLink>
      </ScrollToTop>
    </MainLayout>
  );
};

export default App;
