import styled from "styled-components";
const ContactWrapper = styled.div`
height: 100%;

min-height: 500px;
background-color: #c2d3da;




.contact {
  display: flex;
flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto;


}

.contact-social {
text-align: center;
  width: 95%;

padding-top: 50px;
  margin-top: 10px;

max-width: 650px;
.home-link {

text-align: center;
margin-top: 5px;
h6 {
  padding: 10px;
  color: #222;
  &:hover {
    color: #ffffff;
    transition:  all 0.1s ease-in-out;
  }
}
}

}

  .fa {
  margin: 10px;
    transition: all 0.2s ease-in-out;
    color: #222;
   font-size: calc(2em + 2.5vw);


    &:hover {
      color: #fff;

    }
  }


}

`;


export default ContactWrapper;
