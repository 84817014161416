import styled, { css } from "styled-components";

const Dividor = styled.div`


background-color: #1c92d2;

margin-top: 15px;

width: 24px;
height: 3px;

-webkit-transition: .72s cubic-bezier(.52,.01,.16,1);
-moz-transition: .72s cubic-bezier(.52,.01,.16,1);
transition: .72s cubic-bezier(.52,.01,.16,1);
-webkit-transform: skewY(-16deg) scaleX(1);
-moz-transform: skewY(-16deg) scaleX(1);
-ms-transform: skewY(-16deg) scaleX(1);
-o-transform: skewY(-16deg) scaleX(1);
transform: skewY(-16deg) scaleX(1);
-webkit-transform-origin: 0 100%;
-moz-transform-origin: 0 100%;
-ms-transform-origin: 0 100%;
-o-transform-origin: 0 100%;
transform-origin: 0 100%;

transform: skew(-20deg) rotate(-15deg);
${props =>
  props.primary &&
  css`
width: 16px;
margin-top: 1px;

}


`}
${props =>
  props.primar &&
  css`
background-color: rgb(153, 153, 153);

}

`}

${props =>
  props.pri &&
  css`
margin-top: 1px;

transform: skew(-20deg) rotate(-15deg);
width: 21px;


}

`}
${props =>
  props.primar2 &&
  css`
margin-top: 1px;

transform: skew(-20deg) rotate(-15deg);
width: 21px;
background-color: rgb(153, 153, 153);


}


`}

${props =>
  props.primar3 &&
  css`
margin-top: 1px;

transform: skew(-20deg) rotate(-15deg);
width: 16px;
background-color: rgb(153, 153, 153);


}


`}





`;

export default Dividor;
