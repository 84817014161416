import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoginFormWrapper from '../styled-containers/LoginFormWrapper';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_BASE_URL
    : 'http://localhost:5000';

const LoginForm = () => {
  const history = useHistory();
  const [inputData, setInputData] = useState({ user: '', password: '' });
  const [error, setError] = useState('');

  const handleChange = (event) => {
    event.preventDefault();
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  const onFormSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch(`${baseURL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inputData),
      });
  
      if (response.ok) {
        // Reset the form and clear error
        setInputData({ user: '', password: '' });
        setError('');
  
        // Extract the token from the response
        const { token } = await response.json();
  
        // Store the token in local storage
        localStorage.setItem('token', token);
  
        // Redirect to the desired page
        history.push('/dashboard'); // Redirect to the client-side /dashboard route
      } else {
        // Authentication failed
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error('Error submitting login form', error);
      setError('An error occurred while submitting the form');
    }
  };
  

  
  return (
    <LoginFormWrapper>
      <h2>Hello Pjdux, Please Login</h2>
      <form className="contact-form" onSubmit={onFormSubmit}>
        <label>Username</label>
        <input
          placeholder="Enter Your Username or Email"
          value={inputData.user}
          onChange={handleChange}
          name="user"
          type="text"
        />
        <label>Password</label>
        <input
          placeholder="Enter Your Password"
          value={inputData.password}
          onChange={handleChange}
          type="password"
          name="password"
        />
        {error && <div className="error-message">{error}</div>}
        <input type="submit" className="submit-input" value="Login" />
      </form>
    </LoginFormWrapper>
  );
};

export default LoginForm;
